import React, { useEffect } from "react";
import Footer from "../components/sections/Footer";
import Header from "../components/sections/Header";
import HeadData from "../data/HeadData";
import ContentContainer from "../components/container/ContentContainer";
import ContactExpert from "../components/controls/ContactExpert";
import TitleDiv from "../components/controls/TitleDiv";
import { navigate } from "gatsby";

const ThankYouPage = () => {
  const title = "Thank You";

  useEffect(() => {
    setTimeout(() => navigate("/products"), 10000);
  }, []);

  return (
    <div className="w-full max-w-full relative overflow-hidden">
      <HeadData title={title} />
      <Header />
      <TitleDiv title={title} />
      <ContentContainer
        containerClassName="div-flex"
        className="flex-col md:flex-row gap-x-10 gap-y-4 w-full max-w-6xl py-24"
      >
        <div className="px-4 sm:px-0 md:w-7/12 mx-auto">
          <div className="flex flex-col justify-center items-center leading-relaxed gap-y-8">
            <h1 className="text-actionBlue">Thank You!</h1>
            <p>Your submission has been received.</p>
            <p>A NWP representative will get back to you as soon as possible</p>
          </div>
        </div>
      </ContentContainer>

      <ContactExpert />
      <Footer enableRequestQuote={false} />
    </div>
  );
};

export default ThankYouPage;
